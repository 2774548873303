import { ReactiveController, ReactiveControllerHost } from "lit";
import { TimeLogger } from "../lib/time_logger";
import { PentacodeAPIClient } from "@pentacode/core/src/rest/client";
import { LocalStorage } from "@pentacode/app/src/lib/storage";

const client = new PentacodeAPIClient(`${process.env.PTC_REST_URL || "https://localhost:3005/rest/v1"}` as any);
const logger: TimeLogger = new TimeLogger(client, new LocalStorage());
void logger.init();

export class TimeLogController implements ReactiveController {
    get state() {
        return logger.state;
    }

    get logger() {
        return logger;
    }

    get client() {
        return client;
    }

    private _host: ReactiveControllerHost;

    constructor(host: ReactiveControllerHost) {
        (this._host = host).addController(this);
        logger.subscribe(() => this._host.requestUpdate());

        window.addEventListener("online", () => {
            this.logger.poll();
        });

        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                this.logger.poll();
            }
        });

        void logger.loaded.then(() => {
            const loadingScreen = document.querySelector(".loading-screen") as HTMLElement;
            loadingScreen.style.display = "none";
        });
    }

    hostConnected() {}
    hostDisconnected() {}
}
