import { Dialog } from "@pentacode/app/src/elements/dialog";
import { css, html } from "lit";
import { fullbleed } from "@pentacode/app/src/styles/mixins";
import { state } from "@lit/reactive-element/decorators/state.js";
import { TimeLogController } from "../controllers/time-log";
import { customElement } from "@lit/reactive-element/decorators/custom-element.js";
import { getRange } from "@pentacode/core/src/util";
import { PentacodeAPIModels } from "@pentacode/core/src/rest/api";
import "@pentacode/app/src/elements/date-range-picker";
import { renderFilters } from "../lib/helpers";
import { DateRange } from "@pentacode/core/src/time";

@customElement("ptc-time-schedule-dialog")
export class TimeScheduleDialog extends Dialog<void, void> {
    private _controller = new TimeLogController(this);

    @state()
    private _schedule: PentacodeAPIModels["TimeSchedule"];

    private get _from() {
        return this._schedule.from;
    }

    private get _to() {
        return this._schedule.to;
    }

    async _load({ from, to }: DateRange) {
        const company = this._controller.state.company;
        const device = this._controller.state.device;

        if (!company || !device) {
            return;
        }

        this._schedule = await this._controller.client.api.postTimeSchedule(
            {},
            {
                companyId: company.id,
                from,
                to,
                filters: device.filters,
                mirrorShifts: true,
            }
        );
    }

    show() {
        const { from, to } = getRange(new Date(), "week");

        // Load schedule in the background
        void this._load({ from, to });

        return super.show();
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                ${fullbleed()};
                --dialog-max-width: none;
            }

            .time-display {
                margin-bottom: -0.2em;
            }
        `,
    ];

    renderContent() {
        if (!this._schedule || !this._controller.state.company) {
            return html``;
        }
        return html`<div class="fullbleed vertical layout">
            <div class="relative padded spacing center-aligning horizontal layout border-bottom">
                <div class="stretch semibold ellipsis left-padded">
                    Dienstplan - ${this._controller.state.company?.name} - ${this._controller.state.device?.description}
                </div>

                <div class="tinier">
                    ${renderFilters(this._controller.state.company, this._controller.state.device?.filters || [])}
                </div>

                <button class="slim transparent" @click=${() => this.done()}>
                    <i class="times"></i>
                </button>
            </div>
            <ptc-date-range-picker
                .range=${{ from: this._from, to: this._to }}
                @range-selected=${(e: CustomEvent<DateRange>) => this._load(e.detail)}
                class="half-padded border-bottom"
            ></ptc-date-range-picker>
            <iframe class="stretch" .src="${this._schedule.url}"></iframe>
        </div>`;
    }
}
